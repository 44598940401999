<template>
  <div class="screen-register">
    <div class="container title-container">
      <h2 class="page-title">Welke sporten vind je leuk?</h2>
      <h3 class="page-title smaller">
        Stuur mij invites voor de volgende sportactiviteiten
      </h3>
      <!-- <div class="close-btn"><i class="fal fa-times"></i></div> -->
    </div>
    <form @submit.prevent="routeToRegisterInfo" @change="saveForm">
      <div class="container">
        <div class="form-group">
          <input-field
            default-value="Zoeken"
            :value="searchFilter"
            class="mb-8"
            :clear-icon="true"
            @input="doFilter"
            @clear="clearFilter"
          />
          <activity-list-bar
            :participant-activities="participantActivities"
            :activity-list="filteredList"
          />
          <div class="select-range-header">In een straal van</div>
          <div class="select-range-wrapper">
            <div class="select-range-km">
              <form-select
                v-model="participant.rangeChoice"
                :items="rangeChoices"
                select-arrow-background-color="#FFF"
                select-arrow-color="#009B6D"
                border-color="#FFF"
                empty-text="Afstand"
              />
            </div>
            <div />
            <div class="select-range-binder">van</div>

            <div class="select-range-location">
              <input
                v-model="participant.tempPostalCodeOrCity"
                type="text"
                class="form-control no-border"
                placeholder="1234AB"
                name="tempPostalCodeOrCity"
              />
            </div>
          </div>
          <div v-if="postalCodeError" class="alert alert-danger mt-3">
            {{ postalCodeError }}
          </div>
        </div>
        <button
          type="submit"
          class="btn btn-white btn-block button-next btn-round"
        >
          volgende
        </button>
        <a
          href="#"
          class="btn btn-green-border-white btn-block button-login btn-round mb-5"
          @click.prevent="$router.push('/')"
        >
          terug naar begin
        </a>
      </div>
    </form>
    <div class="background-entrance">
      <div class="bottom">
        <div class="circle" />
      </div>
    </div>
    <spinner-loader v-if="addressCoordinatesLoading" />
  </div>
</template>

<script>
import SpinnerLoader from '@/components/SpinnerLoader'
import ActivityListBar from '@/components/ActivityListBar'
import FormSelect from '@/components/FormSelect'
import InputField from '@/components/InputField'
import data from '@/utils/data'
import { ACTIVITY_LIST } from '../store/actions/activity'
import { LOCATION_ADDRESS_COORDINATES } from '../store/actions/location'
import { mapState } from 'vuex'
import { isValidPostalCode } from '@/utils/helper'

export default {
  name: 'RegisterPreferences',
  components: {
    SpinnerLoader,
    FormSelect,
    ActivityListBar,
    InputField
  },
  data() {
    return {
      searchFilter: undefined,
      filteredList: [],
      postalCodeError: null,
      participant:
        localStorage.getItem('register.participant') !== null
          ? JSON.parse(localStorage.getItem('register.participant'))
          : {
              rangeChoice: null,
              coordinates: null,
              tempPostalCodeOrCity: null,
              postalCode: null,
              city: null,
              commentsUpdate: true
            },
      activitySuggestion:
        localStorage.getItem('register.activitySuggestion') !== null
          ? JSON.parse(localStorage.getItem('register.activitySuggestion'))
          : '',
      rangeChoices: data.rangeChoices,
      participantActivities:
        localStorage.getItem('register.activities') !== null
          ? JSON.parse(localStorage.getItem('register.activities'))
          : [],
      newsletterActivities: false
    }
  },
  computed: {
    ...mapState({
      activityList: (state) => {
        return state.activity.activityList
      },
      participantLoading: (state) => state.participant.status === 'loading',
      addressCoordinatesLoading: (state) => state.location.status === 'loading'
    })
  },
  watch: {
    activityList(list) {
      this.filteredList = list
    }
  },
  created: function () {
    this.$store.dispatch(ACTIVITY_LIST)
    this.filteredList = this.activityList
  },
  methods: {
    isValid: function () {
      let isValid = true
      if (this.participantActivities.length) {
        if (
          !this.participant.tempPostalCodeOrCity ||
          !this.participant.tempPostalCodeOrCity.match(/^\d{4}\s?[A-Za-z]{2}$/)
        ) {
          this.postalCodeError = 'Vul een geldige postcode in'
          isValid = false
        }
        if (!this.participant.rangeChoice) {
          this.postalCodeError = 'Vul een afstand in'
          isValid = false
        }
      }
      return isValid
    },
    saveForm(e) {
      this.resetErrors()
      if (e.target.name === 'tempPostalCodeOrCity') {
        if (isValidPostalCode(this.participant.tempPostalCodeOrCity)) {
          this.participant.postalCode = this.participant.tempPostalCodeOrCity
          this.participant.city = null
        } else {
          this.participant.city = this.participant.tempPostalCodeOrCity
          this.participant.postalCode = null
        }
      }
      localStorage.setItem(
        'register.activitySuggestion',
        JSON.stringify(this.activitySuggestion)
      )
      localStorage.setItem(
        'register.participant',
        JSON.stringify(this.participant)
      )
    },
    routeToRegisterInfo: async function () {
      if (this.isValid()) {
        this.participant.newsletterActivities =
          this.participantActivities.length > 0
        if (this.participant.tempPostalCodeOrCity) {
          const addressResponse = await this.$store.dispatch(
            LOCATION_ADDRESS_COORDINATES,
            this.participant.tempPostalCodeOrCity
          )
          this.participant.coordinates = addressResponse.location
          this.participant.city = addressResponse.city
        }
        localStorage.setItem(
          'register.participant',
          JSON.stringify(this.participant)
        )
        this.$router.push({ path: '/register-info?', query: this.$route.query })
      }
    },
    resetErrors() {
      this.postalCodeError = null
    },
    doFilter(val) {
      this.filteredList = this.activityList.filter((item) => {
        return item.name.toLowerCase().indexOf(val.toLowerCase()) > -1
      })
    },
    clearFilter() {
      this.searchFilter = ''
      this.filteredList = this.activityList
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.page-title {
  font-size: 2rem;
  color: white;
  text-align: center;
  margin: 2rem 0 3rem;
  @media (max-width: 480px) {
    font-size: 1.75rem;
  }
  &.smaller {
    font-size: 1.125rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
  }
}
.screen-register .logo {
  width: 270px;
  margin: -40px auto 0 auto;
  padding: 40px 0;
  max-width: 100%;
}
.screen-register .logo img {
  width: 270px;
  max-width: 100%;
}
.screen-register .form-text {
  color: $ipWhite;
}
.screen-register .screen-register-text {
  color: $ipWhite;
  font-size: 1.25rem;
  margin-bottom: 2.5rem;
  font-weight: inherit;
  text-align: left;
}

.screen-register .select-range-header {
  color: $ipWhite;
  font-size: 1.25rem;
  font-weight: inherit;
  margin: 15px 0;
  text-align: left;
}

.screen-register .select-range-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.screen-register .select-range-wrapper .select-range-km {
  width: 30%;
  display: inline-block;
}

.screen-register .select-range-wrapper .select-range-location {
  flex-grow: 1;
  display: inline-block;
}

.screen-register .select-range-wrapper .select-range-binder {
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $ipWhite;
  font-size: 1.25rem;
  margin: 10px 0 10px 0;
}

.title-container {
  position: relative;
}

.close-btn {
  position: absolute;
  right: 0rem;
  top: 0.25rem;
  color: white;
  i {
    font-size: 2rem;
  }
}

.screen-register .button-next {
  margin: 2rem 0 1rem;
}

@media all and (max-width: 575px) {
  .screen-register .select-range-wrapper {
    flex-direction: column;
  }
  .screen-register .select-range-wrapper .select-range-km {
    width: 100%;
  }

  .screen-register .select-range-wrapper .select-range-location {
    width: 100%;
  }

  .screen-register .select-range-wrapper .select-range-binder {
    align-self: flex-start;
  }
}
</style>
