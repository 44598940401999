<template>
  <div>
    <ul class="flex flex-wrap h-full">
      <li
        v-for="activity in activityList"
        :key="activity.id"
        class="flex"
        @click.prevent="toggleActivity(activity.id)"
      >
        <form-checkbox
          font-size="small"
          checkbox-size="small"
          :label-border="true"
          :label="activity.name"
          :value="participantActivities.indexOf(activity.id) !== -1"
        />
      </li>
    </ul>
  </div>
</template>

<!--
  <div class="form-group">
    <div class="row">
      <div class="col">
        <ul class="activity-list">
          <li
            v-for="activity in firstActivityColumn"
            :key="activity.id"
            class="activity-list-item"
            @click.prevent="toggleActivity(activity.id)"
          >
            <form-checkbox
              :value="participantActivities.indexOf(activity.id) !== -1"
              font-size="small"
              checkbox-size="small"
            >
              <div style="color: #ffffff">
                {{ activity.name }}
              </div>
            </form-checkbox>
          </li>
        </ul>
      </div>
      <div class="col">
        <ul class="activity-list">
          <li
            v-for="activity in secondActivityColumn"
            :key="activity.id"
            class="activity-list-item"
            @click.prevent="toggleActivity(activity.id)"
          >
            <form-checkbox
              :value="participantActivities.indexOf(activity.id) !== -1"
              font-size="small"
              checkbox-size="small"
            >
              <div style="color: #ffffff">
                {{ activity.name }}
              </div>
            </form-checkbox>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template> -->

<script>
import FormCheckbox from '@/components/FormCheckbox'
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
  name: 'ActivityListBar',
  components: {
    FormCheckbox
  },
  props: {
    activityList: {
      type: Array,
      required: true
    },
    participantActivities: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState({
      filteredList: function () {
        return (
          this.activityList.length &&
          this.activityList.filter((i) => i.id !== 39)
        )
      },
      splitListAt: function () {
        return this.filteredList && Math.ceil(this.filteredList.length / 2)
      },
      firstActivityColumn: function () {
        return (
          this.filteredList &&
          this.splitListAt &&
          this.filteredList.slice(0, this.splitListAt)
        )
      },
      secondActivityColumn: function () {
        return (
          this.filteredList &&
          this.splitListAt &&
          this.filteredList.slice(this.splitListAt)
        )
      }
    })
  },
  methods: {
    toggleActivity: function (activityID) {
      // Add or remove item
      if (!_.includes(this.participantActivities, activityID)) {
        this.participantActivities.push(activityID)
      } else {
        const index = this.participantActivities.indexOf(activityID)
        if (index !== -1) {
          this.participantActivities.splice(index, 1)
        }
      }
      localStorage.setItem(
        'register.activities',
        JSON.stringify(this.participantActivities)
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.text-sports {
  color: $ipWhite;
  font-size: 1.25rem;
  font-weight: inherit;
  text-align: left;
}
.activity-list {
  list-style: none;
  padding: 0;
}
</style>
